import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OptionValue, SimpleSelect } from 'react-selectize2';

import SelectToggle from './components/select-toggle/select-toggle';
import Input from '../input/input';
import { InputError } from '../input/input-types';

import generateUID from '../../shared/utils/generate-uid/generate-uid';

import style from './select.scss';

interface SelectProps<T extends OptionValue> {
  ariaLabelledbyId?: string;
  cancelKeyboardEventOnSelection?: boolean;
  className?: string;
  defaultValue?: T;
  error?: InputError;
  filterOptions?: (options: Array<T>, search: string) => Array<T>;
  id?: string;
  infoMessage?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  isDisabled?: boolean;
  isFlipped?: boolean;
  isRequired?: boolean;
  label?: string;
  listboxId?: string;
  onBlur?: () => void;
  onChange?: (item: T) => void;
  onFocus?: () => void;
  options: Array<T>;
  placeholder?: string;
  renderOption?: (
    props: SelectProps<T> | { isSelected: boolean }
  ) => React.ReactElement;
  renderToggleButton?: (props: { open: boolean }) => React.ReactElement;
  renderValue?: (item: T) => React.ReactElement<any, string>;
  shouldAutofocus?: boolean;
  value?: T;
}

function Select<T extends OptionValue>(props: SelectProps<T>) {
  const {
    ariaLabelledbyId,
    cancelKeyboardEventOnSelection = true,
    isFlipped,
    value,
    isDisabled = false,
    id,
    inputProps,
    isRequired,
    // Pass label if you want a placeholder that animates upward to be the label on
    // focus or when a value is selected.  If all you want is a placeholder: pass
    // the placeholder prop.  The placeholder prop and the label prop are mutually
    // exclusive.
    label = '',
    placeholder: placeHolderProp = '',
    renderOption,
    renderToggleButton,
    renderValue,
    shouldAutofocus,
    options = [],
    onBlur,
    onChange,
    onFocus,
    filterOptions,
    value: propsValue
  } = props;
  const placeholder = label ? undefined : placeHolderProp;
  const inputId = useMemo(() => id || generateUID(), [id]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const inputRef = useRef<HTMLDivElement | null>(null);

  //TODO: Need to remove this aria attribute logic when the SimpleSelect component is replaced with ReactSelect component
  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current.querySelector('input.resizable-input');
      if (input && ariaLabelledbyId) {
        input.setAttribute('aria-labelledby', ariaLabelledbyId);
      }
    }
  }, [ariaLabelledbyId]);

  let handleRenderOption:
    | undefined
    | ((item: OptionValue) => React.ReactElement) = undefined;

  if (renderOption) {
    handleRenderOption = props =>
      renderOption({
        ...props,
        isSelected: value?.value === propsValue
      });
  }

  return (
    <Input
      {...{
        ...props,
        isRequired: !placeholder && isRequired
      }}
      id={inputId}
      labelClassName={style['label']}
      styleName={`input ${isOpen ? 'open' : ''} ${isFlipped ? 'flipped' : ''}`}
    >
      <div ref={inputRef}>
        <SimpleSelect
          autofocus={shouldAutofocus}
          cancelKeyboardEventOnSelection={cancelKeyboardEventOnSelection}
          className="simple-select"
          delimiters={[9]}
          disabled={isDisabled}
          dropdownDirection={isFlipped ? -1 : 1}
          filterOptions={filterOptions}
          hideResetButton
          inputProps={inputProps}
          onBlur={onBlur}
          onFocus={onFocus}
          onOpenChange={setIsOpen}
          onValueChange={onChange}
          options={options.filter(option =>
            Boolean(option?.label && option?.value)
          )}
          placeholder={placeholder}
          renderOption={handleRenderOption}
          renderToggleButton={renderToggleButton || SelectToggle}
          renderValue={renderValue}
          value={value}
        />
      </div>
    </Input>
  );
}

export default Select;
