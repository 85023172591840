import React from 'react';
import PropTypes from 'prop-types';

import FitmentTab from './components/fitment-tab/fitment-tab';
import { TABS } from '../../fitment-constants';

import './fitment-tabs.scss';

function FitmentTabs(props) {
  const { hideTabs, onTabSelected, selectedTab } = props;

  function isTabHidden(tab) {
    return Array.isArray(hideTabs) && hideTabs.indexOf(tab) !== -1;
  }

  if (Boolean(hideTabs) && !Array.isArray(hideTabs)) {
    return null;
  }

  return (
    <nav role="tablist" styleName="nav">
      {!isTabHidden(TABS.VEHICLE) && (
        <FitmentTab
          label={TABS.VEHICLE}
          onClick={onTabSelected}
          selectedTab={selectedTab}
        />
      )}
      {!isTabHidden(TABS.SIZE) && (
        <FitmentTab
          label={TABS.SIZE}
          onClick={onTabSelected}
          selectedTab={selectedTab}
        />
      )}
      {!isTabHidden(TABS.BRAND) && (
        <FitmentTab
          label={TABS.BRAND}
          onClick={onTabSelected}
          selectedTab={selectedTab}
        />
      )}
    </nav>
  );
}

FitmentTabs.propTypes = {
  hideTabs: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  onTabSelected: PropTypes.func,
  selectedTab: PropTypes.string
};

export default FitmentTabs;
