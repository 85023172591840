import { stripNonTireSizeCharacters } from '../../shared/utils/fitment';

export function getWheelSize(tireSize) {
  return tireSize?.replace(/.*[^0-9]([0-9]{1,2})\s*$/, '$1');
}

export function toFitmentSize(tireSize) {
  return tireSize?.replace(
    /^\s*([0-9]+)[^0-9]+([0-9]+)[^0-9]+([0-9]+)\s*$/,
    '$1/$2-R$3'
  );
}

export function isOE(updatedFitment, fitment) {
  return (
    stripNonTireSizeCharacters(updatedFitment?.frontTireSize) ===
      stripNonTireSizeCharacters(fitment?.oeFrontTireSize) &&
    stripNonTireSizeCharacters(updatedFitment?.rearTireSize) ===
      stripNonTireSizeCharacters(fitment?.oeRearTireSize)
  );
}

export function isSizeStaggered(size) {
  return /^F\s.+\s-\sR/.test(size);
}

export function vehicleImageUrl(url, vehicleType = 'PAS') {
  if (url) return url;
  const cdn = 'https://cdn.discounttire.com/sys-master/images';
  const defaultURLs = {
    ATV: `${cdn}/hb6/h95/8855851827230/placeholder-atv-utv@2x.png`,
    LT: `${cdn}/h24/h43/8855852187678/placeholder-truck@2x.png`,
    PAS: `${cdn}/h8c/h46/8855852089374/placeholder-car@2x.png`
  };
  return defaultURLs[vehicleType] || defaultURLs.PAS;
}
