import { getSiteLogoUrl } from '../../shared/utils/site';

const logo = getSiteLogoUrl();
const url = window.isDT
  ? 'https://www.discounttire.com'
  : 'https://www.americastire.com';

export const ORGANIZATION_LD = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'USA',
    addressLocality: 'Scottsdale',
    addressRegion: 'AZ',
    postalCode: '85255',
    streetAddress: '20225 North Scottsdale Road'
  },
  contactPoint: [
    {
      '@type': 'ContactPoint',
      areaServed: 'US',
      contactOption: 'TollFree',
      contactType: 'customer support',
      email: 'customerrelations@discounttire.com',
      telephone: '+1-800-385-3322'
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'US',
      contactOption: 'TollFree',
      contactType: 'rebate center',
      telephone: '+1-866-892-8078',
      url: 'https://dt.rebatepromotions.com/'
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'US',
      contactOption: 'TollFree',
      contactType: 'technical support',
      telephone: '+1-888-443-3505'
    },
    {
      '@type': 'ContactPoint',
      contactType: 'Advertising Inquiries: TV & Radio',
      email: 'dtcmedia@discounttire.com'
    }
  ],
  founders: [
    {
      '@type': 'Person',
      name: 'Bruce T. Halle'
    }
  ],
  foundingDate: '1960',
  foundingLocation: 'Ann Arbor, Michigan',
  image: logo,
  logo,
  name: window.siteName,
  sameAs: [
    'https://www.facebook.com/DiscountTire',
    'https://www.linkedin.com/company/discount-tire-company',
    'https://www.twitter.com/discounttire',
    'https://instagram.com/discount_tire/',
    'https://www.youtube.com/user/DiscountTire'
  ],
  url
};

export const WEBSITE_LD = {
  '@context': 'http://schema.org',
  '@type': 'WebSite',
  name: window.siteName,
  potentialAction: {
    '@type': 'SearchAction',
    'query-input': 'required name=search_term_string',
    target: `${url}/search/?text={search_term_string}`
  },
  url
};
