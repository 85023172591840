import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import OptionalSizeGroup from './components/optional-size-group/optional-size-group';
import Collapsible from '../../../../../../../collapsible/collapsible';
import SizeBadge from '../../../../../../../size-badge/size-badge';
import { isSizeStaggered } from '../../../../../../fitment-utils';
import { assemblyPropType } from '../../../../fitment-by-vehicle-util';

import './optional-sizes.scss';

const optionalSizeGrouping = PropTypes.shape({
  offset: PropTypes.string,
  oversizedSizes: PropTypes.arrayOf(assemblyPropType),
  oversizedSizesStaggered: PropTypes.arrayOf(assemblyPropType),
  sizes: PropTypes.arrayOf(assemblyPropType),
  sizesStaggered: PropTypes.arrayOf(assemblyPropType),
  undersizedSizes: PropTypes.arrayOf(assemblyPropType),
  undersizedSizesStaggered: PropTypes.arrayOf(assemblyPropType),
  wheelSize: PropTypes.string
});

const OPTIONAL_PLUS_ZERO_DESCRIPTION =
  'These tire sizes fit the original wheel of your vehicle and generally don’t require new wheels.';

function OptionalSizes(props) {
  const {
    isStaggered,
    optionalSizes,
    selectedAssembly,
    selectedOptionalSize,
    selectedOptionalSizeGrouping,
    setSelectedOptionalSize,
    setSelectedOptionalSizeGrouping
  } = props;

  const [isUndersizeOpen, setIsUndersizeOpen] = useState(false);
  const [isOversizeOpen, setIsOversizeOpen] = useState(false);
  const groupHasSelectedOptionalSize = useCallback(
    grouping =>
      grouping?.some(
        optionalSize =>
          optionalSize?.id && optionalSize.id === selectedOptionalSize?.id
      ),
    [selectedOptionalSize]
  );

  const isSelectedAssemblyStaggered = isSizeStaggered(
    selectedAssembly?.description
  );
  let groupSizes = [];
  let oversizedGroupSizes = [];
  let undersizedGroupSizes = [];

  if (selectedOptionalSizeGrouping) {
    groupSizes = [
      selectedOptionalSizeGrouping.sizes,
      selectedOptionalSizeGrouping.sizesStaggered
    ];
    oversizedGroupSizes = [
      selectedOptionalSizeGrouping.oversizedSizes,
      selectedOptionalSizeGrouping.oversizedSizesStaggered
    ];
    undersizedGroupSizes = [
      selectedOptionalSizeGrouping.undersizedSizes,
      selectedOptionalSizeGrouping.undersizedSizesStaggered
    ];

    if (isSelectedAssemblyStaggered) {
      groupSizes.reverse();
      oversizedGroupSizes.reverse();
      undersizedGroupSizes.reverse();
    }
  }

  useEffect(() => {
    if (selectedOptionalSizeGrouping && selectedOptionalSize) {
      if (
        groupHasSelectedOptionalSize(
          isStaggered
            ? selectedOptionalSizeGrouping.undersizedSizesStaggered
            : selectedOptionalSizeGrouping.undersizedSizes
        )
      ) {
        setIsUndersizeOpen(true);
      } else if (
        groupHasSelectedOptionalSize(
          isStaggered
            ? selectedOptionalSizeGrouping.oversizedSizesStaggered
            : selectedOptionalSizeGrouping.oversizedSizes
        )
      ) {
        setIsOversizeOpen(true);
      }
    }
  }, [
    isStaggered,
    groupHasSelectedOptionalSize,
    selectedOptionalSize,
    selectedOptionalSizeGrouping
  ]);

  return (
    <>
      <p styleName="description">
        Select a wheel size below to see the tires based on that size.
      </p>

      <nav role="tablist" styleName="nav">
        {Array.isArray(optionalSizes) &&
          optionalSizes.map(optionalSizeGrouping => {
            let styleName = 'tab';

            if (selectedOptionalSizeGrouping) {
              if (selectedOptionalSizeGrouping === optionalSizeGrouping) {
                styleName = 'tab-selected';
              } else {
                styleName = 'tab-muted';
              }
            }

            return (
              <button
                key={`tab-${optionalSizeGrouping.wheelSize}-${optionalSizeGrouping.offset}`}
                onClick={() =>
                  setSelectedOptionalSizeGrouping(optionalSizeGrouping)
                }
                styleName={styleName}
              >
                {optionalSizeGrouping.wheelSize}&#8221;
              </button>
            );
          })}
      </nav>

      {selectedOptionalSizeGrouping && (
        <div styleName="container">
          <p styleName="description">
            <SizeBadge
              isOE={false}
              sizeQualifier={selectedOptionalSizeGrouping.offset}
              styleName="offset"
            />
            {selectedOptionalSizeGrouping.offset === '0'
              ? OPTIONAL_PLUS_ZERO_DESCRIPTION
              : 'These tire sizes do not fit the original wheel diameter of your vehicle and require a custom wheel size.'}
          </p>

          <OptionalSizeGroup
            groupSizes={groupSizes}
            selectedOptionalSize={selectedOptionalSize}
            setSelectedOptionalSize={setSelectedOptionalSize}
          />

          {(Boolean(selectedOptionalSizeGrouping.undersizedSizes.length) ||
            Boolean(
              selectedOptionalSizeGrouping.undersizedSizesStaggered.length
            )) && (
            <>
              <Collapsible
                isOpen={isUndersizeOpen}
                label="undersized tires"
                onToggle={() => setIsUndersizeOpen(state => !state)}
                styleName="collapsible"
              >
                <p styleName="description">
                  These smaller tire sizes match your original wheel diameter
                  but may require new wheels due to the narrower tire width, and
                  may impact speedometer accuracy/vehicle handling.
                </p>

                <OptionalSizeGroup
                  groupSizes={undersizedGroupSizes}
                  selectedOptionalSize={selectedOptionalSize}
                  setSelectedOptionalSize={setSelectedOptionalSize}
                />
              </Collapsible>
            </>
          )}

          {(Boolean(selectedOptionalSizeGrouping.oversizedSizes.length) ||
            Boolean(
              selectedOptionalSizeGrouping.oversizedSizesStaggered.length
            )) && (
            <>
              <Collapsible
                isOpen={isOversizeOpen}
                label="oversized tires"
                onToggle={() => setIsOversizeOpen(state => !state)}
                styleName="collapsible"
              >
                <p styleName="description">
                  These larger tire sizes match your original wheel diameter but
                  may require new wheels or fender modifications to prevent
                  rubbing due to the tire height, and may impact speedometer
                  accuracy/vehicle handling.
                </p>

                <OptionalSizeGroup
                  groupSizes={oversizedGroupSizes}
                  selectedOptionalSize={selectedOptionalSize}
                  setSelectedOptionalSize={setSelectedOptionalSize}
                />
              </Collapsible>
            </>
          )}
        </div>
      )}
    </>
  );
}

OptionalSizes.propTypes = {
  isStaggered: PropTypes.bool,
  optionalSizes: PropTypes.arrayOf(optionalSizeGrouping),
  selectedAssembly: assemblyPropType,
  selectedOptionalSize: assemblyPropType,
  selectedOptionalSizeGrouping: optionalSizeGrouping,
  setSelectedOptionalSize: PropTypes.func,
  setSelectedOptionalSizeGrouping: PropTypes.func
};

export default OptionalSizes;
