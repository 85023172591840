import React from 'react';
import PropTypes from 'prop-types';

import { parseTireSize } from '../../../../../../../../../../shared/utils/fitment';

import './fitment-tire-size-option-line.scss';

function FitmentTireSizeOptionLine(props) {
  const { assemblyDescription, isNormalizedSize, label } = props;
  const tireSize = parseTireSize(assemblyDescription);

  return (
    <span styleName="line">
      <span styleName={label ? 'description-with-label' : 'description'}>
        {label && <span styleName="label">{label}</span>}
        {isNormalizedSize && tireSize
          ? `${tireSize.width}/${tireSize.aspectRatio} R${tireSize.diameter}`
          : assemblyDescription}
      </span>
      <span styleName="wheel-size">
        <span>{tireSize?.diameter}&#8221;</span>
        <span>Wheels</span>
      </span>
    </span>
  );
}

FitmentTireSizeOptionLine.propTypes = {
  assemblyDescription: PropTypes.string,
  isNormalizedSize: PropTypes.bool,
  label: PropTypes.string
};

export default FitmentTireSizeOptionLine;
