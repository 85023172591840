import gql from 'graphql-tag';

export const GET_HOME_PAGE = gql`
  query CmsHomePage($id: String!, $siteId: String) {
    cms {
      page(id: $id, siteId: $siteId) {
        documentTitle
        metaTags {
          name
          content
        }
        contentSlots {
          position
          name: id
          cmsComponents {
            ... on CmsBannerCarouselComponent {
              name
              slides {
                link {
                  href
                  text
                  target
                }
                image {
                  altText
                  url
                }
              }
              slideInterval
              id
            }
            ... on CmsHtmlComponent {
              html
            }
            ... on CmsShoppingGuideComponent {
              name
              id
              sections {
                name
                link {
                  href
                  text
                  target
                }
                image {
                  altText
                  url
                }
                heading
                info
              }
            }
            ... on CmsShoppingCategoriesComponent {
              id
              categories {
                link {
                  href
                  text
                  target
                }
                image {
                  altText
                  url
                }
                type
              }
            }
            ... on CmsArticleListComponent {
              articles {
                link {
                  href
                  text
                  target
                }
                image {
                  altText
                  url
                }
                category
                title
                info
              }
            }
            ... on CmsFeaturedArticlesListComponent {
              articles {
                link {
                  href
                  text
                  target
                }
                image {
                  altText
                  url
                }
                description
                id
                heading
              }
            }
            ... on CmsFilteredPromotionsComponent {
              id
              promotions {
                code: id
                link {
                  href
                  text
                  target
                }
                image {
                  altText
                  url
                }
                category
              }
            }
          }
        }
        source
      }
    }
  }
`;
