import React from 'react';
import PropTypes from 'prop-types';

import styles from './fitment-crumbs.scss';

function FitmentCrumbs(props) {
  const { className = '', crumbs, onCrumbSelected, selectedCrumb } = props;

  function getStyleName(crumb) {
    if (selectedCrumb === crumb.label) {
      return 'is-selected';
    } else if (crumb.isActive) {
      return 'is-active';
    } else {
      return null;
    }
  }

  return (
    <div
      className={`fitment-crumbs ${className}`}
      role="tablist"
      styleName="container"
    >
      {crumbs.map(crumb => (
        <button
          aria-disabled={!crumb.isActive}
          aria-selected={selectedCrumb === crumb.label}
          className={styles[getStyleName(crumb)]}
          key={crumb.label}
          onClick={crumb.isActive ? () => onCrumbSelected(crumb.label) : null}
          role="tab"
        >
          {crumb.label}
        </button>
      ))}
    </div>
  );
}

FitmentCrumbs.propTypes = {
  className: PropTypes.string,
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      isActive: PropTypes.bool,
      label: PropTypes.string
    })
  ).isRequired,
  onCrumbSelected: PropTypes.func.isRequired,
  selectedCrumb: PropTypes.string
};

export default FitmentCrumbs;
