import React from 'react';
import { OptionValue } from 'react-selectize2';

import Select from '../../../../../../components/select/select';
import SearchSolidIcon from '../../../../../../shared/icons/search-solid-icon';
import FitmentStepContainer from '../fitment-step-container/fitment-step-container';

import './fitment-select.scss';

interface FitmentSelectProps {
  heading?: React.ReactNode;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  moreInfoContent?: () => JSX.Element;
  onChange: () => void;
  onFocus: () => void;
  onMoreInfoContentClick?: () => void;
  options: OptionValue[];
  placeholder: string;
  selectedOption: OptionValue;
  shouldAutofocus?: boolean;
  subject?: string;
}

function FitmentSelect(props: FitmentSelectProps) {
  const {
    heading,
    inputProps,
    moreInfoContent,
    onChange,
    onFocus,
    options,
    placeholder,
    selectedOption,
    shouldAutofocus = false,
    subject,
    onMoreInfoContentClick
  } = props;

  const defaultHeading = (
    <>
      What is the <strong>{placeholder?.toLowerCase()}</strong> of your{' '}
      {subject || 'vehicle'}?
    </>
  );

  return (
    <FitmentStepContainer
      heading={heading || defaultHeading}
      moreInfoContent={moreInfoContent}
      onMoreInfoContentClick={onMoreInfoContentClick}
    >
      <Select
        ariaLabelledbyId="fitment-step-container-heading"
        inputProps={inputProps}
        isRequired
        label={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        options={options}
        renderToggleButton={SearchSolidIcon}
        shouldAutofocus={shouldAutofocus}
        styleName="select"
        value={selectedOption}
      />
    </FitmentStepContainer>
  );
}

export default FitmentSelect;
