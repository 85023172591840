import React from 'react';
import PropTypes from 'prop-types';

import './fitment-tab.scss';

function FitmentTab(props) {
  const { label, selectedTab, onClick } = props;
  const isSelected = label === selectedTab;

  return (
    <button
      aria-selected={isSelected}
      onClick={() => onClick?.(label)}
      role="tab"
      styleName={isSelected ? 'active-button' : 'default-button'}
    >
      <span>shop by</span>
      <br />
      <span>{label}</span>
    </button>
  );
}

FitmentTab.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selectedTab: PropTypes.string
};

export default FitmentTab;
