import React, { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import Button from '../../library/button/button';
import { defaultErrorState, SELECT, TEXT } from '../../library/form';
import { createField } from '../../library/form/form-utils';
import InputGroup from '../../library/input-group/input-group';
import Modal from '../../library/modal/modal';
import Text from '../../library/text/text';
import Message from '../message/message';
import Select from '../select/select';

import { GET_STORES_BY_SEARCH_TERM_COUNT } from '../store-locator/graphql/store-locator-queries';

import { useForm, useNavigateTo } from '../../shared/hooks/hooks';

import { getIsVisionStore } from '../../shared/utils/store';

import { FIND_STORE_RADIUS_OPTIONS } from '../../shared/constants/store';

import './find-a-store-modal.scss';

interface FindAStoreModalProps {
  isOpen: boolean;
}

function FindAStoreModal(props: FindAStoreModalProps) {
  const { isOpen } = props;
  const client = useApolloClient();
  const [errorText, setErrorText] = useState<string | null>(null);
  const navigateTo = useNavigateTo();

  const {
    fields: { search, storeName, siteNumber, searchRadius },
    updateField: updateFieldForm,
    getFields,
    updateFieldConfig
  } = useForm([
    createField(
      {
        name: 'search'
      },
      TEXT
    ),
    createField(
      {
        name: 'storeName'
      },
      TEXT
    ),
    createField(
      {
        name: 'siteNumber'
      },
      TEXT
    ),
    createField(
      {
        name: 'searchRadius',
        value: FIND_STORE_RADIUS_OPTIONS[3]
      },
      SELECT
    )
  ]);
  const searchValue = search.value;
  const storeNameValue = storeName.value;
  const siteNumberValue = siteNumber.value;

  function updateField(name, value) {
    if (errorText) {
      const fieldsMap = getFields();
      fieldsMap?.forEach(field =>
        updateFieldConfig(field.name, {
          ...field,
          error: {
            ...defaultErrorState
          },
          value: field.name === name ? value : field.value
        })
      );
      setErrorText(null);
    } else {
      updateFieldForm(name, value);
    }
  }

  async function onSearch() {
    const radiusValue = searchRadius.value.value;
    const { data } = await client.query({
      query: GET_STORES_BY_SEARCH_TERM_COUNT,
      variables: {
        radius: radiusValue,
        searchTerm: searchValue || storeNameValue || siteNumberValue
      }
    });
    const isVisionStore = getIsVisionStore(data?.storeWs?.search?.results?.[0]);
    const totalNumberOfResults =
      data?.storeWs?.search?.pagination?.totalNumberOfResults;
    if (totalNumberOfResults > 0 && isVisionStore) {
      navigateTo(
        `/store-locator?search=${searchValue}&storeName=${storeNameValue}&siteNumber=${siteNumberValue}&radius=${radiusValue}`
      );
    } else {
      let activeInput;
      if (searchValue) {
        activeInput = search;
      } else if (storeNameValue) {
        activeInput = storeName;
      } else if (siteNumberValue) {
        activeInput = siteNumber;
      }
      updateFieldConfig(activeInput.name, {
        ...activeInput,
        error: {
          ...defaultErrorState,
          hasErrors: true,
          message: 'No Records Found'
        }
      });
      if (!isVisionStore && totalNumberOfResults > 0) {
        setErrorText('No Vision stores found. Please change your search.');
      } else {
        setErrorText('No records found. Please change your search.');
      }
    }
  }

  return (
    <Modal
      heading={() => <span styleName="header">Find a Store</span>}
      isOpen={isOpen}
      onClose={() => {}}
      styleName="container"
    >
      <div styleName="content">
        <div styleName="subtitle">
          <span>Search by</span>
          <span styleName="text-blurred">
            City, State, Zip Code, Phone, Store Name or Site Number
          </span>
        </div>
        <InputGroup columnCount={1}>
          <Text
            error={search.error}
            isDisabled={storeNameValue || siteNumberValue}
            label="City, State, Zip, or Phone"
            name={search.name}
            onChange={e => updateField(search.name, e.target.value)}
            value={searchValue}
          />
          <Text
            error={storeName.error}
            isDisabled={searchValue || siteNumberValue}
            label="Store Name"
            name={storeName.name}
            onChange={e => updateField(storeName.name, e.target.value)}
            value={storeNameValue}
          />
          <Text
            error={siteNumber.error}
            isDisabled={searchValue || storeNameValue}
            label="Site Number"
            name={siteNumber.name}
            onChange={e => updateField(siteNumber.name, e.target.value)}
            value={siteNumberValue}
          />
          <Select
            label="Search Radius"
            onChange={value => updateField(searchRadius.name, value)}
            options={FIND_STORE_RADIUS_OPTIONS}
            value={searchRadius.value}
          />
        </InputGroup>

        {errorText && (
          <Message
            isLight
            isSolid
            isWarning
            message={errorText}
            styleName="error-banner"
          />
        )}
        <Button
          isDisabled={!(searchValue || storeNameValue || siteNumberValue)}
          isPrimary
          onClick={onSearch}
          styleName="search-store-btn"
        >
          Search
        </Button>
      </div>
    </Modal>
  );
}

export default FindAStoreModal;
