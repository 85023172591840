import React from 'react';

import Image from '../../../../../../../../library/image/image';

import './fitment-tire-size-help-guide.scss';

function FitmentTireSizeHelpGuide() {
  return (
    <div styleName="container">
      <div styleName="left-col">
        <p styleName="content">
          There are two places to find your tire size. Look directly on your
          tire sidewall or inside your driver’s side door jamb for the
          information specific to your vehicle’s original tire size or current
          tire size.
        </p>
        <div styleName="tire-details">
          <Image
            alt="Tire details example"
            src={`${process.env.__CDN_ORIGIN__}/sys-master/images/hc6/h53/9411949297694/Tire_Size_Description_Breakdown.png`}
          />
          <p styleName="details-notice">*example tire size shown</p>
        </div>
      </div>
      <div styleName="right-col">
        <div styleName="tire-sidewall-example">
          <p styleName="title">On tire sidewall example:</p>
          <Image
            alt="Tire details example"
            src={`${process.env.__CDN_ORIGIN__}/sys-master/images/h2e/h1f/9422115831838/Sidewall_Desciption_Pointed_Out_v2.png`}
          />
        </div>
        <div styleName="inside-door-example">
          <p styleName="title">
            Inside door jamb example (to find the original tire size):
          </p>
          <Image
            alt="Inside door jamb example"
            src={`${process.env.__CDN_ORIGIN__}/sys-master/images/h16/hea/9411946250270/Tire_Loading_Information.png`}
          />
        </div>
      </div>
    </div>
  );
}

export default FitmentTireSizeHelpGuide;
