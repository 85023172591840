import React from 'react';

import Typography from '../typography/typography';

import { toInches } from '../../shared/utils/text';

import './size-badge.scss';

interface SizeBadgeProps {
  className?: string;
  isOE?: boolean;
  sizeQualifier?: string;
}

function SizeBadge(props: SizeBadgeProps) {
  const { className = '', sizeQualifier, isOE = false } = props;
  const styleName = `size-badge ${isOE ? 'size-badge-oe' : ''}`;

  return (
    <Typography
      className={`${className} ${styleName}`}
      size="small"
      styleName={styleName}
      tag="span"
      weight="black"
    >
      {isOE
        ? 'O.E.'
        : `${
            sizeQualifier?.length && parseInt(sizeQualifier) > -1 ? '+' : ''
          }${toInches(sizeQualifier)}`}
    </Typography>
  );
}

export default SizeBadge;
