import React, { PropsWithChildren } from 'react';

import Breadcrumbs from './components/breadcrumbs/breadcrumbs';
import Content from './components/content/content';
import Heading from './components/heading/heading';
import PageTitle from './components/page-title/page-title';
import Section from './components/section/section';

interface PageProps {
  className?: string;
}

function Page(props: PropsWithChildren<PageProps>) {
  const { children, className } = props;

  return <div className={`${className || ''} page`}>{children}</div>;
}

export { PageTitle, Breadcrumbs, Content, Heading, Section };
export default Page;
