import React from 'react';

import {
  InfoCircleLightIcon,
  InfoCircleSolidIcon
} from '../../../../shared/icons/icons';
import Button from '../../../button/button';

import './need-help-button.scss';

interface NeedHelpButtonProps {
  className?: string;
  isOpen: boolean;
  label?: string;
  onClick: () => void;
}

function NeedHelpButton(props: NeedHelpButtonProps) {
  const {
    className = 'need-help-button',
    isOpen,
    label = 'need help?',
    onClick
  } = props;
  return (
    <Button
      className={className}
      onClick={onClick}
      size="regular"
      type="button"
    >
      {isOpen ? (
        <InfoCircleSolidIcon styleName="help-me-choose-button-icon" />
      ) : (
        <InfoCircleLightIcon styleName="help-me-choose-button-icon" />
      )}
      {label}
    </Button>
  );
}

export default NeedHelpButton;
