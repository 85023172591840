import React from 'react';

import {
  AngleDownLightIcon,
  AngleUpLightIcon
} from '../../../../shared/icons/icons';

interface SelectToggleProps {
  open?: boolean;
}

function SelectToggle(props: SelectToggleProps) {
  const { open } = props;
  return open ? (
    <AngleUpLightIcon className="select-toggle" />
  ) : (
    <AngleDownLightIcon className="select-toggle" />
  );
}

export default SelectToggle;
