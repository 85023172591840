import React, { PropsWithChildren, useState } from 'react';

import Typography from '../../../../../typography/typography';
import NeedHelpButton from '../../../need-help-button/need-help-button';

import './fitment-step-container.scss';

interface FitmentStepContainerProps {
  className?: string;
  containerRef?: React.MutableRefObject<null>;
  heading: React.ReactNode;
  moreInfoContent?: () => JSX.Element;
  onMoreInfoContentClick?: () => void;
  subHeading?: string;
}

function FitmentStepContainer(
  props: PropsWithChildren<FitmentStepContainerProps>
) {
  const {
    className = '',
    children,
    containerRef,
    heading,
    moreInfoContent,
    subHeading,
    onMoreInfoContentClick
  } = props;

  const [isDisplayingMoreInfo, setIsDisplayingMoreInfo] = useState(false);

  return (
    <div
      className={`fitment-step-container ${className}`}
      ref={containerRef}
      styleName="container"
    >
      <Typography
        className="fitment-step-container__heading"
        id="fitment-step-container-heading"
        styleName="heading"
        variant="H4"
      >
        {heading}
      </Typography>
      {subHeading && <Typography variant="H5">{subHeading}</Typography>}
      {moreInfoContent && (
        <NeedHelpButton
          className="fitment-step-container__more-info-button"
          isOpen={isDisplayingMoreInfo}
          onClick={() => {
            onMoreInfoContentClick?.();
            setIsDisplayingMoreInfo(current => !current);
          }}
          styleName="more-info-button"
        />
      )}
      {typeof moreInfoContent === 'function' && (
        <div
          className="fitment-step-container__more-info"
          styleName={
            isDisplayingMoreInfo ? 'more-info-open' : 'more-info-closed'
          }
        >
          {moreInfoContent()}
        </div>
      )}
      {children}
    </div>
  );
}

export default FitmentStepContainer;
