import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useMessage } from '../../../../../../../../shared/hooks/hooks';

import { cmsContentParser } from '../../../../../../../../shared/utils/cms';

import { RIDE_HEIGHT_TYPES } from '../../../../../../../../shared/constants/fitment';
import {
  FITMENT_LIFTED_SIZES_MESSAGE,
  FITMENT_LOWERED_SIZES_MESSAGE,
  FITMENT_OPTIONAL_SIZES_MESSAGE
} from '../../../../../../../../shared/constants/messages';

import './optional-sizes-heading.scss';

function OptionalSizesHeading(props) {
  const { selectedRideHeight } = props;
  const messages = useMessage([
    FITMENT_LOWERED_SIZES_MESSAGE,
    FITMENT_LIFTED_SIZES_MESSAGE,
    FITMENT_OPTIONAL_SIZES_MESSAGE
  ]);

  const { optionalSizeHeading, optionalSizeMessage } = useMemo(() => {
    switch (selectedRideHeight?.type) {
      case RIDE_HEIGHT_TYPES.LIFTED:
        return {
          optionalSizeHeading: 'Optional Lifted Tire Sizes',
          optionalSizeMessage: messages?.[FITMENT_LIFTED_SIZES_MESSAGE]
        };
      case RIDE_HEIGHT_TYPES.LOWERED:
        return {
          optionalSizeHeading: 'Optional Lowered Tire Sizes',
          optionalSizeMessage: messages?.[FITMENT_LOWERED_SIZES_MESSAGE]
        };
      case RIDE_HEIGHT_TYPES.STOCKHEIGHT:
        return {
          optionalSizeHeading: 'Aftermarket Tire Sizes',
          optionalSizeMessage: messages?.[FITMENT_OPTIONAL_SIZES_MESSAGE]
        };
      default:
        return {
          optionalSizeHeading: 'Optional Tire Sizes',
          optionalSizeMessage: messages?.[FITMENT_OPTIONAL_SIZES_MESSAGE]
        };
    }
  }, [selectedRideHeight, messages]);

  if (!messages) {
    return null;
  }

  return (
    <div styleName="container">
      <h4 styleName="optional-heading">{optionalSizeHeading}</h4>
      <p styleName="optional-message">
        {cmsContentParser(optionalSizeMessage)}
      </p>
    </div>
  );
}

OptionalSizesHeading.propTypes = {
  selectedRideHeight: PropTypes.string
};

export default OptionalSizesHeading;
