import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import FitmentTireSizeOptionLine from './components/fitment-tire-size-option-line/fitment-tire-size-option-line';
import {
  assemblyPropType,
  isAssemblyOptionEqual
} from '../../../../fitment-by-vehicle-util';

import './fitment-tire-size-option.scss';

const styleNames = {
  falsefalse: 'size-option',
  falsetrue: 'size-option-selected',
  truefalse: 'size-option-staggered',
  truetrue: 'size-option-staggered-selected'
};

function FitmentTireSizeOption(props) {
  const {
    assembly,
    isNormalizedSize = false,
    isSelected: isSelectedProp,
    onClick,
    selectedAssembly
  } = props;
  const isStaggered = Boolean(assembly?.frontTireDescription);
  const isSelected =
    isSelectedProp === undefined &&
    isAssemblyOptionEqual(assembly, selectedAssembly);

  const onClickWithAssembly = useCallback(() => {
    onClick(assembly);
  }, [assembly, onClick]);

  return (
    <button
      aria-checked={isSelected}
      onClick={onClickWithAssembly}
      role="radio"
      styleName={styleNames[`${isStaggered}${isSelected}`]}
    >
      {isStaggered ? (
        <>
          <FitmentTireSizeOptionLine
            assemblyDescription={assembly?.frontTireDescription}
            isNormalizedSize={isNormalizedSize}
            label="Front"
          />
          <FitmentTireSizeOptionLine
            assemblyDescription={assembly?.rearTireDescription}
            isNormalizedSize={isNormalizedSize}
            label="Rear"
          />
        </>
      ) : (
        <FitmentTireSizeOptionLine
          assemblyDescription={assembly?.description}
          isNormalizedSize={isNormalizedSize}
        />
      )}
    </button>
  );
}

FitmentTireSizeOption.propTypes = {
  assembly: assemblyPropType,
  isNormalizedSize: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  selectedAssembly: assemblyPropType
};

export default FitmentTireSizeOption;
