import React from 'react';
import ReactLazyLoad from 'react-lazyload';

interface LazyLoadProps {
  children: React.ReactNode | React.ReactNode[];
  offset?: number;
  placeholder?: React.ReactNode;
}

// This component allows us to disable lazy loading in a prerender.io env
const LazyLoad = (props: LazyLoadProps) => {
  let children = props.children;

  return (
    <ReactLazyLoad
      {...props}
      offset={window.__IS_PRERENDER_IO_ENV__ ? 10000 : props.offset}
    >
      {typeof children === 'function' ? children() : children}
    </ReactLazyLoad>
  );
};

export default LazyLoad;
