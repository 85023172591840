import { gql } from 'apollo-boost';

export const GET_LAYOUT = gql`
  query CmsHeaderFooter($siteId: String) {
    cms {
      globalHeader(siteId: $siteId) {
        navigationNodes {
          quickLinks {
            id
            name
            url
          }
          linkGroups {
            id
            name
            groupLink {
              id
              name
              url
            }
            links {
              name
              url
            }
          }
          id
          name
        }
        source
      }
      globalFooter(siteId: $siteId) {
        copyright
        messageContent
        footerZone2
        promoContent
        primaryLinks {
          name
          links {
            name
            url
            openInNewTab
          }
        }
        secondaryLinks {
          name
          url
          openInNewTab
        }
        socialLinks {
          name
          url
          openInNewTab
        }
        source
      }
    }
  }
`;
