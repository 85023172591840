import React, { useState } from 'react';

import { CRUMBS } from '../../../../library/fitment/fitment-constants';
import TabAsHeader from '../../../../library/tab-as-header/tab-as-header';

import { trackAction } from '../../../../shared/utils/analytics/analytics';

import {
  VEHICLE_FORM_TAB_LABELS,
  VEHICLE_FORM_TABS
} from '../../constants/tabs';

import './fitment-tab-header.scss';

interface FitmentTabHeaderProps {
  onTabFocus?: (string) => void;
  setSelectedVehicleCrumb: (string) => void;
  showGenericForm?: boolean;
}

function FitmentTabHeader(props: FitmentTabHeaderProps) {
  const { onTabFocus, setSelectedVehicleCrumb, showGenericForm } = props;
  const [vehicleFormSelectedTab, setVehicleFormSelectedTab] = useState(
    showGenericForm
      ? VEHICLE_FORM_TAB_LABELS[VEHICLE_FORM_TABS.GENERIC]
      : VEHICLE_FORM_TAB_LABELS[VEHICLE_FORM_TABS.STANDARD]
  );

  return (
    <TabAsHeader
      onTabSelect={selectedTab => {
        setVehicleFormSelectedTab(selectedTab);
        if (
          selectedTab === VEHICLE_FORM_TAB_LABELS[VEHICLE_FORM_TABS.GENERIC]
        ) {
          onTabFocus?.(VEHICLE_FORM_TABS.GENERIC);
          setSelectedVehicleCrumb(VEHICLE_FORM_TABS.GENERIC);
        } else {
          setSelectedVehicleCrumb(CRUMBS.YEAR);
          onTabFocus?.(VEHICLE_FORM_TABS.STANDARD);
        }

        trackAction(
          `shop_page_select_${
            selectedTab === VEHICLE_FORM_TAB_LABELS[VEHICLE_FORM_TABS.STANDARD]
              ? 'new_vehicle'
              : 'generic_vehicle'
          }`
        );
      }}
      selectedTab={vehicleFormSelectedTab}
      styleName="add-vehicle-tabs"
      tabs={[
        VEHICLE_FORM_TAB_LABELS[VEHICLE_FORM_TABS.STANDARD],
        VEHICLE_FORM_TAB_LABELS[VEHICLE_FORM_TABS.GENERIC]
      ]}
    />
  );
}

export default FitmentTabHeader;
