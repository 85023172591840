import PropTypes from 'prop-types';

export const assemblyPropType = PropTypes.shape({
  assemblyId: PropTypes.string,
  description: PropTypes.string
});

export function isAssemblyOptionEqual(assemblyA, assemblyB) {
  if (!assemblyA || !assemblyB) {
    return false;
  }

  if (assemblyA.id && assemblyB.id) {
    return assemblyA.id === assemblyB.id;
  }

  if (
    assemblyA.frontTireDescription &&
    assemblyA.rearTireDescription &&
    assemblyB.frontTireDescription &&
    assemblyB.rearTireDescription
  ) {
    return (
      assemblyA.frontTireDescription === assemblyB.frontTireDescription &&
      assemblyA.rearTireDescription === assemblyB.rearTireDescription
    );
  }

  if (assemblyA.description && assemblyB.description) {
    return assemblyA.description === assemblyB.description;
  }

  return false;
}

export const modelPropType = PropTypes.shape({
  description: PropTypes.string,
  vehicleId: PropTypes.string
});

export const trimPropType = PropTypes.shape({
  description: PropTypes.string,
  trimId: PropTypes.string
});

export const rideHeightPropType = PropTypes.shape({
  description: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string
});
