import React from 'react';

import './section.scss';

interface SectionProps {
  children?: React.ReactNode;
  className?: string;
  heading?: React.ReactNode;
  id?: string;
  intro?: React.ReactNode;
  isFullWidth?: boolean;
  showDivider?: boolean;
  styleName?: string;
}

export const Section = React.forwardRef<HTMLElement, SectionProps>(
  function Section(props, ref) {
    const {
      className,
      children,
      showDivider = false,
      heading,
      id,
      intro,
      isFullWidth = false
    } = props;

    return (
      <section
        className={`${className || ''} section`}
        id={id}
        ref={ref}
        styleName={showDivider ? 'container-w-divider' : 'container'}
      >
        {heading && <h3 styleName="heading">{heading}</h3>}

        {intro && <div styleName="intro">{intro}</div>}

        <div
          className={className ? `${className}__content` : ''}
          data-testid="section-content"
          styleName={isFullWidth ? 'content-fullwidth' : 'content'}
        >
          {children}
        </div>
      </section>
    );
  }
);

export default Section;
