export function toInches(value) {
  const inchMarks = '’’';
  if (
    typeof value === 'string' &&
    value.substring(value.length - 2) === inchMarks
  ) {
    return value;
  }

  if (value > -Infinity) {
    return `${value}${inchMarks}`;
  }

  return `0${inchMarks}`;
}
