import { useCallback, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import cookies from 'js-cookie';
import fetch from 'unfetch';

import { MyAccountContext } from '../../contexts';
import { useFitment } from '../hooks';

import { trackView } from '../../utils/analytics/analytics';
import { getVehicleDataLayerValues } from '../../utils/fitment';
import { localStorage as ls } from '../../utils/storage';
import { getStoreDataLayerValues } from '../../utils/store';

import {
  AFFILIATE,
  GOOGLE_ANALYTICS,
  UTAG
} from '../../constants/cookie-names';
import {
  AFFILIATE_ID,
  DAYS_SINCE_LAST_VISIT,
  DOCUMENT_REFERRER,
  DOCUMENT_REFERRERS,
  ENVIRONMENT,
  IDME_GROUP,
  IS_AUTHENTICATED,
  IS_CUSTOMER,
  IS_IDME_AUTHENTICATED,
  IS_PDL,
  IS_POS,
  NUMBER_OF_VISITS,
  PAGE_HOST,
  PAGE_PATH,
  PAGE_TYPE,
  PAGE_URL,
  PAGE_VIEWABLE_PERCENTAGE,
  PREV_PAGE_VIEWED_PERCENTAGE,
  SITE_VERSION,
  TEST_NAMES,
  TEST_VARIANTS,
  TEST_VERSION,
  USER_ID
} from '../../constants/data-layer';
import {
  DAYS_SINCE_LAST_VISIT as DAYS_BETWEEN_LAST_VISIT,
  IDME_IS_VERIFIED,
  IDME_VERIFIED_GROUP,
  LAST_VISIT,
  MY_STORE,
  REFERRERS,
  TREADWELL_IS_IN_FLOW
} from '../../constants/storage-keys';

const SITE_ENVIRONMENTS = {
  dev: 'development',
  prd: 'production',
  qa: 'qa',
  stg: 'stg'
};

const dtAtRegEx =
  /discounttire|americastire|discounttiredirect|dtui|dtdev|dtqa/;

export function initializeDataLayer() {
  if (!window.__DATA_LAYER__) {
    window.__DATA_LAYER__ = {
      history: [],
      page: null,
      session: null
    };
  }
}

export function getUtagInfo() {
  let utagInfo = cookies.get(UTAG) || {
    _sn: undefined,
    ses_id: undefined
  };

  if (typeof utagInfo === 'string') {
    utagInfo = utagInfo
      .replace(/;exp-session/g, '')
      .split('$')
      .reduce(
        (acc, v) => {
          const [key, value] = v.split(':');
          acc[key] = value;
          return acc;
        },
        {
          _sn: undefined,
          ses_id: undefined
        }
      );
  }

  return utagInfo;
}

export function getReferrers() {
  const documentReferrer = document.referrer;
  const referrers: Set<string> = new Set(ls(REFERRERS) || []);

  if (!dtAtRegEx.test(documentReferrer) || !referrers.has(documentReferrer)) {
    referrers.add(documentReferrer);
    ls(REFERRERS, [...referrers]);
  }

  return [...referrers];
}

export function getDaysSinceLastVisit(visitTimeStamp) {
  const lastVisitTimeStamp = parseInt(ls(LAST_VISIT));

  if (window.__DATA_LAYER__?.history?.length === 0) {
    const currentTime = new Date().getTime();
    const msInDay = 86400000;
    const diff = currentTime - lastVisitTimeStamp || 0;
    const daysSinceLastVisit = diff / msInDay;

    ls(LAST_VISIT, visitTimeStamp);
    ls(DAYS_BETWEEN_LAST_VISIT, daysSinceLastVisit.toFixed(2));
  }

  return ls(DAYS_BETWEEN_LAST_VISIT);
}

export function updateSessionDataLayerValues() {
  const { _sn: sessionCount, ses_id: visitTimeStamp } = getUtagInfo();

  if (window.__DATA_LAYER__ && !window.__DATA_LAYER__?.session) {
    window.__DATA_LAYER__.session = {
      [AFFILIATE_ID]: cookies.get(AFFILIATE) || null,
      [DAYS_SINCE_LAST_VISIT]: getDaysSinceLastVisit(
        parseInt(visitTimeStamp || '0')
      ),
      [DOCUMENT_REFERRERS]: getReferrers(),
      [ENVIRONMENT]:
        process.env.__SITE_ENV__ !== undefined
          ? SITE_ENVIRONMENTS[process.env.__SITE_ENV__] || ''
          : '',
      [IS_CUSTOMER]: '1',
      [IS_POS]: '0',
      [NUMBER_OF_VISITS]: sessionCount || null,
      [SITE_VERSION]: process.env.__APP_VERSION__,
      [TEST_NAMES]: [],
      [TEST_VARIANTS]: [],
      [TEST_VERSION]: ''
    };
  }
}

export function updatePageDataLayerValues(
  pageValues,
  shouldCreateNewEntry = true
) {
  if (typeof pageValues === 'object') {
    if (shouldCreateNewEntry && window.__DATA_LAYER__?.page) {
      const history = [...window.__DATA_LAYER__.history];

      history.push(window.__DATA_LAYER__.page);
      window.__DATA_LAYER__.history = history.slice(-19);
    }

    if (!shouldCreateNewEntry && window.__DATA_LAYER__) {
      window.__DATA_LAYER__.page = {
        ...window.__DATA_LAYER__.page,
        ...pageValues
      };
    } else if (window.__DATA_LAYER__) {
      window.__DATA_LAYER__.page = pageValues;
    }
  }
}

function useDataLayer({ shouldResetPageOnFitmentChange = false } = {}) {
  const { pathname, search } = useLocation();
  const { selectedFitment } = useFitment();
  const { isLoggedIn, user } = useContext(MyAccountContext);
  const prevPageUrlRef = useRef<string | undefined>();
  const pageUrl = `${pathname}${search}`;
  const userId = user?.customerId || '';

  const updateDataLayer = useCallback(
    (pageValues, shouldCreateNewEntry = true) => {
      const hasTrackedPage = prevPageUrlRef.current === pageUrl;

      if (hasTrackedPage && shouldCreateNewEntry) return;

      const hasNavigatedFromWithinApp = Boolean(window.__DATA_LAYER__?.page);

      let defaultPageValues = {
        [DOCUMENT_REFERRER]:
          hasNavigatedFromWithinApp || dtAtRegEx.test(document.referrer)
            ? 'Non-Referred Page'
            : document.referrer,
        [IDME_GROUP]: ls(IDME_VERIFIED_GROUP),
        [IS_AUTHENTICATED]: `${isLoggedIn}`,
        [IS_IDME_AUTHENTICATED]: ls(IDME_IS_VERIFIED) ? '1' : '0',
        [IS_PDL]: ls(TREADWELL_IS_IN_FLOW) ? '1' : '0',
        [PAGE_HOST]: window.location.hostname,
        [PAGE_PATH]: window.location.pathname,
        [PAGE_URL]: window.location.href,
        [PAGE_VIEWABLE_PERCENTAGE]: `${Math.round(
          (window.innerHeight /
            (document.getElementById('root')?.offsetHeight || 1)) *
            100
        )}%`
      };

      if (shouldCreateNewEntry) {
        const myStore = ls(MY_STORE);

        if (isLoggedIn && userId) {
          defaultPageValues[USER_ID] = userId;
        }

        if (window.prevPageViewedPercentage) {
          defaultPageValues[
            PREV_PAGE_VIEWED_PERCENTAGE
          ] = `${window.prevPageViewedPercentage}`;
        }

        if (myStore) {
          defaultPageValues = {
            ...defaultPageValues,
            ...getStoreDataLayerValues(myStore)
          };
        }

        if (selectedFitment) {
          defaultPageValues = {
            ...defaultPageValues,
            ...getVehicleDataLayerValues(selectedFitment)
          };
        }

        updateSessionDataLayerValues();
      }

      updatePageDataLayerValues(
        {
          ...defaultPageValues,
          ...pageValues
        },
        shouldCreateNewEntry
      );

      window.utag_data = {
        tealium_account: 'discounttire',
        ...(window.__DATA_LAYER__?.session || {}),
        ...(window.__DATA_LAYER__?.page || {})
      };

      const viewData = window.utag_data;

      /*
       * Send first page view to Event Stream also so we can capture
       * accurate bounce rates while waiting for the Tealium tag to load
       */
      if (window.__DATA_LAYER__?.history?.length || 0 < 1) {
        if (process.env.__EVENTSTREAM_ENABLED__ === 'true') {
          viewData.api_send = '1';

          const gaCookie = cookies.get(GOOGLE_ANALYTICS);
          const eventPayload = {
            ...viewData,
            ga_client_id: gaCookie?.substring(6),
            tealium_datasource: 'bfn6k8',
            tealium_event: 'page_view',
            tealium_profile: 'main'
          };

          fetch('https://collect.tealiumiq.com/event', {
            body: JSON.stringify(eventPayload),
            method: 'POST'
          });

          if (process.env.__SITE_ENV__ === 'local') {
            console.log('initial trackView:', eventPayload);
          }
        } else {
          viewData.timing_event = 'asap_load';
        }
      }

      trackView(viewData);

      prevPageUrlRef.current = pageUrl;
    },
    [selectedFitment, isLoggedIn, userId, pageUrl]
  );

  useEffect(() => {
    if (shouldResetPageOnFitmentChange) {
      prevPageUrlRef.current = undefined;
    }
  }, [selectedFitment, shouldResetPageOnFitmentChange]);

  useEffect(() => {
    initializeDataLayer();
  }, []);

  return {
    currentPageType: window.__DATA_LAYER__?.page?.[PAGE_TYPE],
    updateDataLayer
  };
}

export default useDataLayer;
