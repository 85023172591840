import React from 'react';
import PropTypes from 'prop-types';

import { assemblyPropType } from '../../../../../../fitment-by-vehicle-util';
import FitmentTireSizeOption from '../../../fitment-tire-size-option/fitment-tire-size-option';

function OptionalSizeGroup(props) {
  const { selectedOptionalSize, setSelectedOptionalSize, groupSizes } = props;

  if (!Array.isArray(groupSizes)) return null;

  return (
    <div role="radiogroup">
      {groupSizes.map((optionalSizes, idx) => {
        if (Array.isArray(optionalSizes)) {
          return (
            <>
              {optionalSizes.map(optionalSize => (
                <FitmentTireSizeOption
                  assembly={optionalSize}
                  isNormalizedSize
                  key={`optional-size-${optionalSize.id}`}
                  onClick={setSelectedOptionalSize}
                  selectedAssembly={selectedOptionalSize}
                />
              ))}
              {groupSizes.length - 1 !== idx && <br />}
            </>
          );
        }

        return null;
      })}
    </div>
  );
}

OptionalSizeGroup.propTypes = {
  groupSizes: PropTypes.arrayOf(assemblyPropType).isRequired,
  selectedOptionalSize: assemblyPropType,
  setSelectedOptionalSize: PropTypes.func
};

export default OptionalSizeGroup;
