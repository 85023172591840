import React from 'react';

import Button from '../../library/button/button';
import { AngleLeftLightIcon } from '../../shared/icons/icons';

import './back-button.scss';

interface BackButtonProps {
  className?: string;
  href?: string;
  onClick?: () => void;
  text: string;
}

function BackButton(props: BackButtonProps) {
  const { className, href, onClick, text } = props;

  return (
    <Button
      className={className}
      href={href}
      onClick={() => {
        if (onClick) onClick();
      }}
      styleName="back-button"
      type="button"
    >
      <AngleLeftLightIcon aria-hidden="true" styleName="back-icon" />
      {text}
    </Button>
  );
}

export default BackButton;
